@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

#license {
  font-family: "Courgette", cursive;
}

.dashboard-btn-active {
  @apply bg-white drop-shadow-md hover:cursor-pointer;
}

.dashboard-btn-disable {
  @apply bg-white opacity-60;
}
